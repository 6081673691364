requirejs(['jquery', 'jsb'], function ($, jsb) {
	var ajaxURL = gulpConfig.customs.sidebar.content_path;

	$.ajax({
		url: ajaxURL,
		type: 'GET',
		tab: [],
		success: function (data) {
			// Get Sidebar Content
			var sidebarPage = $(data), // plain loaded sidebar html
				tabData = sidebarPage.find('.module-tabs'),
				// Create wrap	per elements for tabs
				sidebar = $("<div id='vrsb_mySidenav' class='vrsb_sidenav'>"),
				tab = $("<div class='vrsb_tab'>"),
				tabcontents = $("<div class='wrapper-vrsb_tabcontent'>");

			var mobileTrigger = $('<div class="mobileTrigger">'),
				mobileInfo = $('<i class="fa fa-info">'),
				mobileClose = $('<i class="fa fa-close">');

			mobileTrigger.append(mobileInfo, mobileClose);

			// @todo build with already existing icons
			// var mobileTrigger = $(ogr.svg.createSvgElement('mobileTrigger')).append(
			// 	ogr.svg.createUseElement('#icon-cancel', 'icon-sidebar-close'), 
			// 	ogr.svg.createUseElement('#icon-attention', 'icon-sidebar-info')
			// );

			$(document.body).append(mobileTrigger, sidebar);
			var mobileTriggerPoints = $([mobileTrigger, tab, sidebar]);

			/**
			 * Create sidebar buttons & contents
			 */
			tabData.find(".tab-wrapper").each(function (t) {
				var tabWrapper = $(this);
				var img = $(this).find('.module-imagebox .content img').last().attr('data-src');
				var linkName = $(this).find('.module-linklist ul li a').last().text().trim();
				var tabButton = $('<button class="vrsb_tablinks">');

				var tabButtonContent = $('<span class="vrsb_icons"><img src="' + img + '" /></span>' +
					'<span class="vrsb_tab_text">' + $(this).children('.tab').html() + '</span>');

				tab.append(tabButton);
				tabButton.append(tabButtonContent);

				/**
				 * Button Click Functions
				 */
				if (typeof linkName !== 'undefined' && linkName == 'Direktlink') {
					var directLink = tabWrapper.find('.module-linklist ul li a').last().attr('href');
					tabButton.click(function () {
						location.href = directLink;
					})
				} else {
					tabButton.click(function () {
						setActiveTab($(this));
					})
				}
				// $(this).find('.figure .image img').last().remove();				

				tabcontent = $('<div class="vrsb_tabcontent">').append($(this).find('.tab-inner').contents()); // hier wird zum Schluss "einfach" das HTML von .tab-inner übergeben
				tabcontents.append(tabcontent);
			});

			var buttons = tab.children('.vrsb_tablinks');
			var contentItems = tabcontents.children('.vrsb_tabcontent');

			contentItems.mouseleave(unsetTabs);

			/**
			 * Sets new active tab & unsets all old ones
			 * @param  {jQuery Object} button
			 */
			function setActiveTab(button) {
				var btnIndex = buttons.index(button);
				buttons.removeClass('vrsb_active')
					.filter(button)
					.addClass('vrsb_active');

				tabcontents.css('display', 'block');

				contentItems
					.css('display', 'none')
					.eq(btnIndex).css('display', 'block');
			}
			/**
			 * deactivates all tab contents & buttons
			 */
			function unsetTabs() {
				buttons.removeClass('vrsb_active');
				$(tabcontents).add(contentItems).css('display', 'none');

			}
			// @todo is this necessary? 
			$(document).on('touchstart', function (event) {
				if (!$(event.target).closest('#vrsb_mySidenav').length) {
					unsetTabs();
				}
			});

			mobileTrigger.click(function () {
				mobileTriggerPoints.toggleClass("mobile");
				unsetTabs();
			});

			// @todo build with already existing icons
			mobileInfo.click(function () {
				mobileTriggerPoints.removeClass("mobile-close")
			});
			mobileClose.click(function () {
				mobileTriggerPoints.addClass("mobile-close");
				unsetTabs();
			});

			sidebar.append(tab);
			tab.append(tabcontents);
			this.tab = tab;
		},
		complete: function () {
			var $tab = this.tab;

			requirejs(['kscchat'], function (kscchat) {
				// Chat button enable
				var chatButton = $tab.find('.button-chat');
				chatButton.each(function () {
					kscchat($(this)[0], {
						kind: "chat"
					});
				});

				// Co-Browsing button enable
				var cobrowseButton = $tab.find('.button-cobrowsing');
				cobrowseButton.each(function () {
					kscchat($(this)[0], {
						kind: "cobrowse"
					});
				});
			})
		}
	});

});